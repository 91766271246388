<template>
  <div class="relative top-0 left-0 min-h-screen w-full bg-white">
    <ul class="py-[0.125rem]" data-accordion>
      <li
        v-for="(layer1, index) in nav.tree"
        :key="index"
        :class="`${layer1?.page?.color_theme?.value || 'blue'}-el`"
      >
        <Accordion
          v-if="layer1.children.length > 0"
          class="group w-full border-b border-gray-800/20"
        >
          <template #summary>
            <div
              class="focus-visible:outline-2 focus-visible:-outline-offset-2 focus-visible:outline-blue-500 green-el:focus-visible:outline-green-500 pink-el:focus-visible:outline-pink-500"
            >
              <div
                class="focus-default flex cursor-pointer justify-between !rounded-none py-5 px-4 text-base font-medium group-open:font-semibold group-open:text-white blue-el:group-open:bg-blue-500 green-el:group-open:bg-green-500 pink-el:group-open:bg-pink-500"
              >
                {{ layer1.page.title }}
                <IconPlus
                  class="w-6 text-blue-500 group-open:text-white lg:w-8"
                />
              </div>
            </div>
          </template>
          <template #content>
            <ul
              class="flex flex-col divide-y divide-blue-500 overflow-hidden px-4 delay-75 duration-300 green-el:divide-green-500 pink-el:divide-pink-500"
            >
              <!-- LEVEL 2 -->
              <li
                v-for="(layer2, i) in layer1.children"
                :key="i"
                class="hidden group-open:block"
              >
                <!-- Render link if link -->
                <NuxtLink
                  v-if="layer2.page.url"
                  :to="layer2.page.url"
                  class="focus-default -m-2 my-7 block w-fit p-2 text-2xs font-bold uppercase leading-none text-gray-800"
                >
                  {{ layer2.page.title }}
                  <IconArrowDash class="-mt-0.5 inline-block w-5" />
                </NuxtLink>
                <h5
                  v-else
                  class="py-7 text-2xs font-bold uppercase leading-none text-gray-800"
                >
                  {{ layer2.page.title }}
                </h5>
                <!-- LEVEL 3 -->
                <ul
                  v-if="layer2.children.length > 0"
                  class="flex flex-col space-y-2 pb-7"
                >
                  <li
                    v-for="(layer3, i) in layer2.children"
                    :key="i"
                    class="list-none"
                  >
                    <NuxtLink
                      v-if="layer3.page.url"
                      :to="layer3.page.url"
                      class="focus-default -m-2 inline-block p-2 text-base hover:text-blue-600 hover:green:text-green-600 hover:pink:text-pink-600 hover:green-el:text-green-600 hover:pink-el:text-pink-600"
                    >
                      {{ layer3.page.title }}
                    </NuxtLink>
                  </li>
                </ul>
              </li>
            </ul>
          </template>
        </Accordion>

        <NuxtLink
          v-else-if="layer1.page.url"
          class="relative flex w-full cursor-pointer justify-between rounded-none border-b border-gray-800/20 py-5 px-4 text-base font-medium focus:rounded-none focus-visible:outline-2 focus-visible:-outline-offset-2 focus-visible:outline-blue-500 group-open:font-semibold group-open:text-white blue-el:group-open:bg-blue-500 green-el:focus-visible:outline-green-500 green-el:group-open:bg-green-500 pink-el:focus-visible:outline-pink-500 pink-el:group-open:bg-pink-500"
          :to="layer1.page.url"
          >{{ layer1.page.title }}</NuxtLink
        >
      </li>
    </ul>

    <ul class="mt-20 w-full px-4 text-right">
      <li
        v-for="(el, index) in meta.tree"
        :key="index"
        class="mt-7 text-base hover:text-blue-600 hover:green:text-green-600 hover:pink:text-pink-600 hover:green-el:text-green-600 hover:pink-el:text-pink-600"
      >
        <NuxtLink
          v-if="el.page.url"
          :to="el.page.url"
          class="focus-default -m-2 p-2"
        >
          <IconHeart
            v-if="el.page.hasHeart"
            class="mr-2 -mt-1 inline-block w-4"
          />{{ el.page.title }}</NuxtLink
        >
      </li>
      <li v-if="isInLivePreview" class="mt-7 flex w-full justify-end text-base">
        <NavigationSimpleLang />
      </li>
    </ul>
  </div>
</template>

<script setup>
defineProps({
  nav: { type: Object, required: true },
  meta: { type: Object, required: true },
})
const route = useRoute()
const isInLivePreview = !!route.query['live-preview']

function toggleHandler(event) {
  // Only run if accordion is open
  if (!event.target.hasAttribute('open')) return

  // Only run on accordions inside our selector
  let parent = event.target.closest('[data-accordion]')
  if (!parent) return

  // Get all open accordions inside parent
  let opened = parent.querySelectorAll('details[open]')

  // Close open ones that aren't current accordion
  for (let accordion of opened) {
    if (accordion === event.target) continue
    accordion.removeAttribute('open')
  }
}

document.addEventListener('toggle', toggleHandler, true)
</script>

<style scoped>
.router-link-active,
.router-link-exact-active {
  @apply text-blue-500 green-el:text-green-500 pink-el:text-pink-500;
}
</style>
